h1{
    color: white;
    position: relative;
    padding: 10px;
    padding-left: 0px;
    font-size: 30px;
    font-family: 'Chakra Petch';
}

.select-month{
    position: relative;
    width: 300px;
    height: 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid rgb(243, 175, 98);
    border-radius: 12px;
    padding-left: 15px;
    opacity: 1;   
    left: 50px;
    font-family: 'Chakra Petch';
    margin-bottom: 25px;
}

.table-daily{
    padding: 5px;
    padding-left: 70px;
    position: relative;
    right: 25px;
}

.table-monthly{
    padding: 5px;
    padding-left: 70px;
    position: relative;
    right: 25px;
}

.header-monthly{
    padding-left: 50px;
}

@media (max-width: 768px) {
    table tbody {
      font-size: 16px;
    }
  
    table th {
      font-size: 20px;
    }
  }

  #contact-links {
    position: fixed;
    left: 0;
    top: 50%;
    background: #fcfcfc;
    border: 1px solid #2d2d2d;
    border-radius: 12px;
    padding: 10px;
    width: 250px;
    transition: transform 0.3s ease-in-out;
    z-index: 9998;
    transform: translateX(-100%);
    font-family: 'Raleway';
  }
  
  #contact-links:hover {
      transform: translateX(0);
  }
  
  #trigger {
      position: absolute;
      right: -25px;
      top: 50%;
      background: #cdbb71;
      border: 2px solid #2d2d2d;
      border-radius: 0px 12px 12px 0px;
      padding: 10px;
      cursor: pointer;
      z-index: 9999;
      transform: translateY(-50%);
      background-color: #2d2d2d;
      font-weight: bolder;
      color: #cdbb71;
  }
  
  #contact-links a {
      display: block;
      margin-bottom: 5px;
      color: black;
      text-decoration: none;
  }
  
  #contact-links a:hover {
      color: #cdbb71;
  }
  
  .image-next{
      position: relative;
      left: 8px;
      font-weight: bolder;
  }
  
  .img-contact{
      max-width: 20px;
      max-height: 20px;
  }
  
  .text-contact{
      position: relative;
      left: 20px;
      bottom: 0px;
      font-size: 25px;
  }
  
  .wa-img{
      position: relative;
      max-width: 32px;
      max-height: 32px;
      right: 2px;
  }
  
  .fb-img{
      position: relative;
      max-width: 27px;
      max-height: 27px;
  }
  
  .tele-img{
      position: relative;
      max-width: 33px;
      max-height: 33px;
      right: 2px;
  }
  
  .ig-img{
      position: relative;
      max-width: 28px;
      max-height: 28px;
      left: 1px;
  }
  
  .wa-text{
      position: relative;
      bottom: 10px;
      padding-left: 35px;
      font-family: 'Raleway';
  }
  
  .fb-text{
      position: relative;
      bottom: 10px;
      padding-left: 39px;
      font-family: 'Raleway';
  }
  
  .tele-text{
      position: relative;
      bottom: 10px;
      padding-left: 34px;
      font-family: 'Raleway';
  }
  
  .web-text{
      position: relative;
      bottom: 7px;
      padding-left: 39px;
      font-family: 'Raleway';
  }

  .text-censored{
    color: red !important;
  }