.header-logo{
    cursor: pointer;
    margin-top: 20px;
    padding: 20px;
    max-width: 100%;
}


.menu-button{
    position: relative;
    cursor: pointer;
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    top: -65px;
    left: 1200px;
}

.menu-button:focus {
    outline: none;
  }

.menu-items {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.menu-items li {
  margin-right: 1rem;
}

.menu-items li:last-child {
  margin-right: 0;
}

.menu-items a {
  text-decoration: none;
  color: #ffffff;
}

.menu-items a:hover {
  color: #ff8500;
  font-weight: 600;
  text-decoration: none !important;
}

@media only screen and (max-width: 600px) {
  .menu-button{
      position: static;
      font-size: 16px;
      top: -50px;
      left: 90%;
  }

  .header-logo{
      cursor: pointer;
      margin-top: 20px;
      padding: 20px;
      max-width: 100%;
      margin-left: 30px;
  }

  .menu-items{
      flex-direction: row;
      padding-left: 41px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .menu-button{
      font-size: 18px;
      top: -60px;
      left: 90%;
  }

  .header-logo{
      padding: 15px;
  }

  .menu-items{
      flex-direction: row;
      justify-content: space-evenly;
  }
}