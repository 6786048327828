.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #1e1e1e;
}

.main-content {
  flex: 1;
  width: 100%;
}
